import React, {useEffect} from 'react';
import {navigate} from '@reach/router';

const Pricing = () => {
  useEffect(() => {
    navigate('/pricing/');
  }, []);

  return <></>;
};

export default Pricing;
